import {DirectUpload} from "@rails/activestorage";

export default async function directUpload(file, url = "/rails/active_storage/direct_uploads", delegate) {
  return new Promise((resolve, reject) => {
    const upload = new DirectUpload(file, url, delegate);
    upload.create(function (error, blob) {
      if (error) return reject(error);
      return resolve(blob);
    });
  });
}
