import {organizerEventFeeUrl, organizerTicketTypeFeeUrl} from "~/apiRoutes";
import {mkDeepSnakeCase, performAction} from "~/utils/index";

export default async function fetchFees({addons, eventId, ticketTypeId, amount, feeIncluded, planId, salesChannel}) {
  const url = ticketTypeId ? organizerTicketTypeFeeUrl({id: ticketTypeId}) : organizerEventFeeUrl({id: eventId});
  return await performAction({
    url,
    body: mkDeepSnakeCase({amount, feeIncluded, planId, salesChannel, addons}),
    successResponseMapper: async (res) => await res.json(),
  });
}
