import {t} from "~/i18n";

export default function validateMessages() {
  const typeTemplate = t("errors.messages.invalid");

  return {
    default: t("errors.messages.invalid"),
    required: true,
    enum: "'${name}' must be one of [${enum}]",
    whitespace: t("errors.messages.blank"),
    date: {
      format: t("errors.messages.invalid"),
      parse: t("errors.messages.invalid"),
      invalid: t("errors.messages.invalid"),
    },
    types: {
      string: typeTemplate,
      method: typeTemplate,
      array: typeTemplate,
      object: typeTemplate,
      number: typeTemplate,
      date: typeTemplate,
      boolean: typeTemplate,
      integer: typeTemplate,
      float: typeTemplate,
      regexp: typeTemplate,
      email: typeTemplate,
      url: typeTemplate,
      hex: typeTemplate,
    },
    string: {
      len: t("errors.messages.wrong_length"),
      min: t("errors.messages.greater_than_or_equal_to", {count: "${min}"}),
      max: t("errors.messages.less_than_or_equal_to", {count: "${max}"}),
      range: [
        t("errors.messages.greater_than_or_equal_to", {count: "${min}"}),
        t("errors.messages.less_than_or_equal_to", {count: "${max}"}),
      ].join(", "),
    },
    number: {
      len: t("errors.messages.wrong_length"),
      min: t("errors.messages.greater_than_or_equal_to", {count: "${min}"}),
      max: t("errors.messages.less_than_or_equal_to", {count: "${max}"}),
      range: [
        t("errors.messages.greater_than_or_equal_to", {count: "${min}"}),
        t("errors.messages.less_than_or_equal_to", {count: "${max}"}),
      ].join(", "),
    },
    array: {
      len: "'${name}' must be exactly ${len} in length",
      min: "'${name}' cannot be less than ${min} in length",
      max: "'${name}' cannot be greater than ${max} in length",
      range: "'${name}' must be between ${min} and ${max} in length",
    },
    pattern: {
      mismatch: "'${name}' does not match pattern ${pattern}",
    },
  };
}
