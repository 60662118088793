import {t} from "~/i18n";

export default function ticketTypeLabel(type) {
  return (
    {
      DonationTicketType: t("events.form.ticket_types.donation_text"),
      AttendanceTicketType: t("events.form.ticket_types.attend_text"),
      FreeTicketType: t("events.form.ticket_types.free_text"),
      FoodAndBeverageTicketType: t("events.form.ticket_types.food_and_beverage"),
      MerchandiseTicketType: t("events.form.ticket_types.merchandise"),
      NoPdfPayTicketType: t("events.form.ticket_types.no_pdf_pay_text"),
      PayTicketType: t("events.form.ticket_types.pay_text"),
    }[type] || type
  );
}
