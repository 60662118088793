export {default as clamp} from "./clamp";
export {default as classNames} from "./classNames";
export {default as compact} from "./compact";
export {default as compile} from "./compile";
export {default as cookie} from "./cookie";
export {default as copyTextToClipboard} from "./copyTextToClipboard";
export {default as debug} from "./debug";
export {default as decorateEventWithBadge} from "./decorateEventWithBadge";
export {default as directUpload} from "./directUpload";
export {default as downloadImage} from "./downloadImage";
export {default as durationDetails} from "./durationDetails";
export {default as errorResponse} from "./errorResponse";
export {default as eventDates} from "./eventDates";
export {default as fetchFees} from "./fetchFees";
export {default as formatDate} from "./formatDate";
export {default as formatPhone} from "./formatPhone";
export {default as getCountryCode} from "./getCountryCode";
export {default as getTimeZone} from "./getTimeZone";
export {default as googleCalendarUrl} from "./googleCalendarUrl";
export {default as handleResponse} from "./handleResponse";
export {default as inPlaceDeepCamelCase} from "./inPlaceDeepCamelCase";
export {default as inPlaceTransform} from "./inPlaceTransform";
export {default as internalRef} from "./internalRef";
export {default as isFacebookBrowser} from "./isFacebookBrowser";
export {default as isMainImage} from "./isMainImage";
export * from "./jsonapi";
export {default as mergeRefs} from "./mergeRefs";
export {default as mkDeepCamelCase} from "./mkDeepCamelCase";
export {default as mkDeepSnakeCase} from "./mkDeepSnakeCase";
export * as notification from "./notification";
export {default as parameterize} from "./parameterize";
export {default as partition} from "./partition";
export {default as performAction} from "./performAction";
export {default as reportMetric} from "./reportMetric";
export {default as round} from "./round";
export * from "./sets";
export {default as shortDate} from "./shortDate";
export {default as tagUrl} from "./tagUrl";
export {default as template} from "./template";
export {default as ticketTypeLabel} from "./ticketTypeLabel";
export {default as trackEvent} from "./trackEvent";
export {default as updateVisitor} from "./updateVisitor";
export {default as validateMessages} from "./validateMessages";
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/;

export function bref(object) {
  return btoa(JSON.stringify({...object, t: Math.round(new Date().getTime() / 1000)}));
}

export const buildGoogleMapsLinkToDestination = (destination, travelMode) => {
  const baseURL = "https://www.google.com/maps/dir/?api=1";
  const params = new URLSearchParams({
    origin: "current+location",
    destination: destination,
    travelmode: travelMode,
  });
  return `${baseURL}&${params.toString()}`;
};
