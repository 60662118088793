import {customNotify} from "~/bugsnag";
import request from "~/fetch";
import {deserialize, errorResponse} from "~/utils/index";

function defaultSuccessResponseMapper() {
  return {success: true};
}

async function resourceMapper(resource, response) {
  const json = await response.json();
  return {
    success: true,
    [resource]: deserialize(json),
    meta: json?.meta,
    links: json?.links,
  };
}

export default async function performAction({
  url,
  headers,
  body,
  signal,
  method = "post",
  resource,
  successResponseMapper = defaultSuccessResponseMapper,
}) {
  try {
    const res = await request(url, {
      headers,
      body,
      signal,
      method: method.toUpperCase(),
    });
    if (res.ok)
      return await (resource
        ? resourceMapper(resource, res)
        : successResponseMapper(res));
    return await errorResponse(res);
  } catch (error) {
    if (error.name !== "AbortError") customNotify(error);
    return {error};
  }
}
