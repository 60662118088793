import {Transition} from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {render} from "react-dom";

import {gtag} from "~/modules/gtmTracking";
import classNames from "~/utils/classNames";

const notificationsWrapper = document.createElement("div");
notificationsWrapper.setAttribute("id", "notifications-drop");
notificationsWrapper.setAttribute("aria-live", "assertive");
notificationsWrapper.setAttribute(
  "class",
  "z-[500] pointer-events-none fixed inset-0 flex px-4 py-6 items-start sm:p-6",
);
const notificationsTarget = document.createElement("div");
notificationsTarget.setAttribute("class", "flex w-full flex-col items-center space-y-4 sm:items-end");
notificationsWrapper.appendChild(notificationsTarget);
document.body?.appendChild(notificationsWrapper);

export function Notification({type, icon, message, description, duration = 5, onClose}) {
  const [show, setShow] = useState(false);
  const timeout = useRef(null);
  const IconComponent = icon;
  const handleClose = useCallback(() => {
    setShow(false);
    setTimeout(() => onClose?.(), 200);
    if (timeout.current) clearTimeout(timeout.current);
  }, [onClose]);
  const iconClass =
    {
      warn: "text-yellow-400",
      error: "text-red-400",
      success: "text-green-400",
      info: "text-brand-400",
    }[type] || "text-green-400";
  useEffect(() => {
    setShow(true);
    timeout.current =
      duration > 0
        ? setTimeout(() => {
            setShow(false);
            setTimeout(() => onClose?.(), 200);
          }, duration * 1000)
        : null;

    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, [duration, onClose]);

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="shrink-0">
              <IconComponent className={classNames("h-6 w-6", iconClass)} aria-hidden="true" />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900 dark:text-white">{message}</p>
              {description ? <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">{description}</p> : null}
            </div>
            <div className="ml-4 flex shrink-0">
              <button
                type="button"
                className="inline-flex rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2"
                onClick={handleClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

const activeNotifications = new Set();

export function notify({type, key, icon, message, description, duration, onClose}) {
  if (key && activeNotifications.has(key)) return;
  const notification = document.createElement("div");
  notification.setAttribute("class", "w-full max-w-sm");
  const handleClose = () => {
    notificationsTarget.removeChild(notification);
    if (key) activeNotifications.delete(key);
    onClose?.();
  };
  icon ||= {
    warn: ExclamationTriangleIcon,
    error: ExclamationCircleIcon,
    success: CheckCircleIcon,
    info: InformationCircleIcon,
  }[type];
  notificationsTarget.appendChild(notification);
  activeNotifications.add(key);
  render(
    <Notification
      type={type}
      icon={icon}
      message={message}
      description={description}
      duration={duration}
      onClose={handleClose}
    />,
    notification,
  );
}

export function error(props) {
  gtag("event", "error_message", {error_message: props.message});
  notify({...props, type: "error"});
}

export function warning(props) {
  notify({...props, type: "warn"});
}

export function success(props) {
  notify({...props, type: "success"});
}

export function info(props) {
  notify({...props, type: "info"});
}

export default {error, warning, success, info};
