export default function googleCalendarUrl(event) {
  if (event.startsAt && event.endsAt) {
    const params = new URLSearchParams({
      action: "TEMPLATE",
      dates: [
        new Date(event.startsAt).toISOString(),
        new Date(event.endsAt).toISOString(),
      ]
        .map((date) => date.replace(/[-:]/g, ""))
        .join("/"),
      text: event.name,
      trp: false,
    });
    return `https://www.google.com/calendar/event?${params}`;
  }
  return "";
}
