import "whatwg-fetch";

import {getCSRFToken} from "~/csrf";
import i18n from "~/i18n";
import debug from "~/utils/debug";

let visitorId = window.visitorSessionId ?? null;

export function serializeBody(body) {
  if (body instanceof FormData) return body;
  if (body && typeof body !== "string") return JSON.stringify(body);
  return body;
}

function request(url, {headers = {}, body, method, signal}) {
  const requestBody = serializeBody(body);
  const requestHeaders = {
    Accept: "application/vnd.api+json, application/json",
    "Accept-Language": i18n.language ?? document.documentElement.lang,
    "X-Billetto-Client": "web",
    "X-CSRF-Token": getCSRFToken(),
    "X-Version": VERSION,
    ...headers,
  };
  if (visitorId) requestHeaders["X-Visitor-Id"] = visitorId;
  if (window?.bootstrap?.vid) requestHeaders["X-Visitor-Id"] = window.bootstrap.vid;
  if (!requestHeaders["Content-Type"] && typeof requestBody === "string")
    requestHeaders["Content-Type"] = "application/vnd.api+json";
  return fetch(url, {
    credentials: "same-origin",
    headers: requestHeaders,
    method: method || "GET",
    body: debug(requestBody, `Requesting ${url} with body:`),
    signal: "AbortSignal" in window && signal instanceof AbortSignal ? signal : undefined,
  })
    .then((response) => {
      if (response.headers.get("X-Visitor-Id")) visitorId = response.headers.get("X-Visitor-Id");
      return response;
    })
    .then((response) => debug(response, `Response from ${url}:`));
}

export default request;

export const get = (url, overrides = {}) => request(url, {...overrides, method: "GET"});
export const post = (url, body, signal) => request(url, {body, method: "POST", signal});
export const patch = (url, body, signal) => request(url, {body, method: "PATCH", signal});
export const put = (url, body, signal) => request(url, {body, method: "PUT", signal});
export const destroy = (url, body, signal) => request(url, {body, method: "DELETE", signal});

export const getWithRetry = (url, overrides = {}) => {
  const {retryDelay, retryOn} = overrides;

  return new Promise(function (resolve, reject) {
    const wrappedGet = (retryCount) => {
      const {onRetry} = overrides;
      if (onRetry && retryCount > 0) onRetry(retryCount);
      get(url, overrides)
        .then((response) => {
          if (response.status != (retryOn || 202)) {
            resolve(response);
          } else {
            setTimeout(() => wrappedGet(retryCount + 1), retryDelay || 1000);
          }
        })
        .catch(reject);
    };

    wrappedGet(0);
  });
};
