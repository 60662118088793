import {bref} from "~/utils/index";

export default function tagUrl(url, metadata) {
  if (metadata) {
    const targetUrl = new URL(url);
    const params = new URLSearchParams(targetUrl.search);
    if (metadata.bref) params.set("bref", metadata.bref);
    else {
      const ref = {};
      if (metadata.utmSource) ref.s = metadata.utmSource;
      if (metadata.utmMedium) ref.m = metadata.utmMedium;
      if (metadata.utmCampaign) ref.c = metadata.utmCampaign;
      if (metadata.utmContent) ref.co = metadata.utmContent;
      params.set("bref", bref(ref));
    }
    targetUrl.search = params;
    return targetUrl.toString();
  }
  return url;
}
