import {add, fromUnixTime, isBefore} from "date-fns";

import {t} from "~/i18n";

export default function decorateEventWithBadge(event, sponsored = false) {
  if (sponsored)
    return {
      ...event,
      badge: {label: t("generic.sponsored"), class: "bg-brand-500 text-white mr-2"},
    };
  if (event.event_availability === "sold_out") return event;
  if (event.event_availability === "low")
    return {
      ...event,
      badge: {label: t("purchase.flow_few.tix.left"), class: "bg-orange-100 text-orange-700 mr-2"},
    };
  if (
    event.kind !== "scheduled" &&
    event.start_time &&
    isBefore(fromUnixTime(event.start_time), add(new Date(), {days: 7})) &&
    isBefore(new Date(), fromUnixTime(event.start_time))
  )
    return {
      ...event,
      badge: {label: t("purchase.flow.starts_soon"), class: "bg-green-100 text-green-700 mr-2"},
    };
  if (event.published_time && isBefore(add(new Date(), {days: -7}), fromUnixTime(event.published_time)))
    return {
      ...event,
      badge: {label: t("purchase.flow.new"), class: "bg-brand-50 text-brand-700 mr-2"},
    };
  return event;
}
