import {render, unmountComponentAtNode} from "react-dom";

import {Button, Event, Events, OrganiserButton, OrganiserCalendar, OrganiserList} from "~/components/widgets";
import {generatePallette} from "~/components/WidgetStylesheet";
import i18n from "~/i18n";
import classNames from "~/utils/classNames";
import compact from "~/utils/compact";
import {deserialize} from "~/utils/jsonapi";

class BillettoWidget extends HTMLElement {
  constructor() {
    super();
    this.widgetId = Math.random().toString(36).slice(2);
  }

  shadow = this.attachShadow({mode: "open"});

  connectedCallback() {
    window.addEventListener("message", this.analyticsEvent.bind(this));
    this.render();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.render();
    }
  }

  unmount() {
    unmountComponentAtNode(this.shadow);
  }

  disconnectedCallback() {
    this.unmount();
  }

  analyticsEvent(message) {
    if (message.data.type === "billetto-widget" && message.data.payload?.extra?.widgetId === this.widgetId) {
      const event = new CustomEvent(message.data.event, {
        detail: compact({
          ...message.data.payload,
          extra: null,
          widget: null,
        }),
      });
      this.dispatchEvent(event);
    }
  }

  getStyles(organization, color, theme, fontFamily = "") {
    const pallette = generatePallette(color, theme);
    return `@import "//${organization}/widget-styles.css";
  @import "https://fonts.googleapis.com/css?family=Roboto:300,400,400italic,500,700,700italic&display=swap";
  :host {
    all: initial;
    display: block;
  }

  * {
    box-sizing: border-box;
    border: 0;
  }

  .billetto-container {
    font-family: ${fontFamily} "Roboto", sans-serif;
    ${pallette}
  }

  .billetto-frame {
    width: 100%;
    height: 100%;
  }

  .billetto-iframe {
    position: fixed;
    top: 0px;
    left: 0px;
    margin: 0;
    padding: 0;
    border: none;
    width: 100vw;
    height: 100vh;
    z-index: 2147483647;
    background-color: rgba(0,0,0,0.25);
  }`;
  }
}

if (!customElements.get("billetto-button"))
  customElements.define(
    "billetto-button",
    class BillettoButton extends HTMLElement {
      constructor() {
        super();
        this.widgetId = Math.random().toString(36).slice(2);
      }

      connectedCallback() {
        window.addEventListener("message", this.analyticsEvent.bind(this));
        this.render();
      }

      attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
          this.render();
        }
      }

      unmount() {
        unmountComponentAtNode(this);
      }

      disconnectedCallback() {
        this.unmount();
      }

      analyticsEvent(message) {
        if (message.data.type === "billetto-widget" && message.data.payload?.extra?.widgetId === this.widgetId) {
          const event = new CustomEvent(message.data.event, {
            detail: compact({
              ...message.data.payload,
              extra: null,
              widget: null,
            }),
          });
          this.dispatchEvent(event);
        }
      }

      render() {
        const event = this.getAttribute("event");
        const color = this.getAttribute("color");
        const organization = this.getAttribute("organization");
        const theme = this.getAttribute("theme");
        const lang = this.getAttribute("lang");
        const hideBanners = this.hasAttribute("hide-banners");
        const whitelabel = this.hasAttribute("whitelabel");
        const noDirectSales = this.hasAttribute("no-direct-sales");
        i18n.changeLanguage(lang);

        render(
          <>
            <style dangerouslySetInnerHTML={{__html: "billetto-button { display: contents; }"}}></style>
            <Button
              headless
              lang={lang}
              event={event}
              color={color}
              organization={organization}
              theme={theme}
              whitelabel={whitelabel}
              hideBanners={hideBanners}
              noDirectSales={noDirectSales}
              widgetId={this.widgetId}
              thirdParty
            />
          </>,
          this,
        );
      }

      static get observedAttributes() {
        return [
          "color",
          "event",
          "lang",
          "hide-banners",
          "no-direct-sales",
          "theme",
          "whitelabel",
          "organization",
          "type",
        ];
      }
    },
  );

if (!customElements.get("billetto-widget"))
  customElements.define(
    "billetto-widget",
    class BillettoEventWidget extends BillettoWidget {
      constructor() {
        super();
      }

      render() {
        const event = this.getAttribute("event");
        const color = this.getAttribute("color");
        const organization = this.getAttribute("organization");
        const theme = this.getAttribute("theme");
        const type = this.getAttribute("type");
        const lang = this.getAttribute("lang");
        const hideBanners = this.hasAttribute("hide-banners");
        const whitelabel = this.hasAttribute("whitelabel");
        const noDirectSales = this.hasAttribute("no-direct-sales");
        const Element = type === "event" ? Event : Button;
        const styles = this.getStyles(organization, color, theme);
        i18n.changeLanguage(lang);

        render(
          <>
            <style>{styles}</style>
            <div
              className={classNames(
                "billetto-container flex justify-center items-center max-w-5xl mx-auto",
                theme === "dark" ? "dark" : null,
              )}
            >
              <Element
                lang={lang}
                event={event}
                color={color}
                organization={organization}
                theme={theme}
                whitelabel={whitelabel}
                hideBanners={hideBanners}
                noDirectSales={noDirectSales}
                widgetId={this.widgetId}
                thirdParty
              />
            </div>
          </>,
          this.shadow,
        );
      }

      static get observedAttributes() {
        return [
          "color",
          "event",
          "lang",
          "hide-banners",
          "no-direct-sales",
          "theme",
          "whitelabel",
          "organization",
          "type",
        ];
      }
    },
  );

if (!customElements.get("billetto-organiser-button"))
  customElements.define(
    "billetto-organiser-button",
    class BillettoOrganiserButton extends HTMLElement {
      constructor() {
        super();
        this.widgetId = Math.random().toString(36).slice(2);
      }

      connectedCallback() {
        window.addEventListener("message", this.analyticsEvent.bind(this));
        this.render();
      }

      attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
          this.render();
        }
      }

      unmount() {
        unmountComponentAtNode(this);
      }

      disconnectedCallback() {
        this.unmount();
      }

      analyticsEvent(message) {
        if (message.data.type === "billetto-widget" && message.data.payload?.extra?.widgetId === this.widgetId) {
          const event = new CustomEvent(message.data.event, {
            detail: compact({
              ...message.data.payload,
              extra: null,
              widget: null,
            }),
          });
          this.dispatchEvent(event);
        }
      }

      render() {
        const organiser = this.getAttribute("organiser");
        const color = this.getAttribute("color");
        const organization = this.getAttribute("organization");
        const theme = this.getAttribute("theme");
        const lang = this.getAttribute("lang");
        const whitelabel = this.hasAttribute("whitelabel");
        const includePrivate = this.hasAttribute("include-private");
        const hideBanners = this.hasAttribute("hide-banners");
        const noDirectSales = this.hasAttribute("no-direct-sales");
        i18n.changeLanguage(lang);

        render(
          <>
            <style dangerouslySetInnerHTML={{__html: "billetto-organiser-button { display: contents; }"}}></style>
            <OrganiserButton
              headless
              lang={lang}
              organiserId={organiser}
              color={color}
              organization={organization}
              hideBanners={hideBanners}
              theme={theme}
              whitelabel={whitelabel}
              includePrivate={includePrivate}
              noDirectSales={noDirectSales}
              widgetId={this.widgetId}
              thirdParty
            />
          </>,
          this,
        );
      }

      static get observedAttributes() {
        return ["color", "event", "lang", "hide-banners", "no-direct-sales", "theme", "whitelabel", "organization"];
      }
    },
  );

if (!customElements.get("billetto-organiser-widget"))
  customElements.define(
    "billetto-organiser-widget",
    class BillettoOrganiserWidget extends BillettoWidget {
      constructor() {
        super();
      }

      render() {
        const organiser = this.getAttribute("organiser");
        const color = this.getAttribute("color");
        const organization = this.getAttribute("organization");
        const theme = this.getAttribute("theme");
        const type = this.getAttribute("type");
        const lang = this.getAttribute("lang");
        const whitelabel = this.hasAttribute("whitelabel");
        const includePrivate = this.hasAttribute("include-private");
        const hideBanners = this.hasAttribute("hide-banners");
        const noDirectSales = this.hasAttribute("no-direct-sales");
        const Element = type === "calendar" ? OrganiserCalendar : type === "list" ? OrganiserList : OrganiserButton;
        const styles = this.getStyles(organization, color, theme);
        i18n.changeLanguage(lang);

        render(
          <>
            <style>{styles}</style>
            <div
              className={classNames(
                "billetto-container flex justify-center items-center max-w-5xl mx-auto",
                theme === "dark" ? "dark" : null,
              )}
            >
              <Element
                lang={lang}
                organiserId={organiser}
                color={color}
                organization={organization}
                hideBanners={hideBanners}
                theme={theme}
                whitelabel={whitelabel}
                includePrivate={includePrivate}
                noDirectSales={noDirectSales}
                widgetId={this.widgetId}
                thirdParty
              />
            </div>
          </>,
          this.shadow,
        );
      }

      static get observedAttributes() {
        return [
          "color",
          "organiser",
          "hide-banners",
          "lang",
          "theme",
          "whitelabel",
          "include-private",
          "no-direct-sales",
          "organization",
          "type",
        ];
      }
    },
  );

if (!customElements.get("billetto-events"))
  customElements.define(
    "billetto-events",
    class BillettoOrganiserWidget extends BillettoWidget {
      constructor() {
        super();
      }

      render() {
        const organization = this.getAttribute("organization");
        const partner = this.getAttribute("partner");
        fetch(`https://${organization}/api/partner_widgets/${partner}`)
          .then(async (widgetResponse) => {
            if (widgetResponse.ok) {
              const widget = deserialize(await widgetResponse.json());
              const {color, theme, lang, query, searchOptions, tags, fontFamily} = widget.options;
              const styles = this.getStyles(organization, color || "#056fda", theme, fontFamily);
              i18n.changeLanguage(lang);
              render(
                <>
                  <style>{styles}</style>
                  <div
                    className={classNames(
                      "billetto-container flex justify-center items-center max-w-5xl mx-auto",
                      theme === "dark" ? "dark" : null,
                    )}
                  >
                    <Events color={color} theme={theme} searchOptions={searchOptions} tags={tags} query={query} />
                  </div>
                </>,
                this.shadow,
              );
            } else console.error("Failed to fetch widget");
          })
          .catch(console.error);
      }

      static get observedAttributes() {
        return ["partner", "organization"];
      }
    },
  );
