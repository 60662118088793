import Color from "color";
import {Helmet} from "react-helmet";

const RATIO = 0.1;
const constrastRatio = 1.5;

const getLighterColor = (color, steps, theme) => {
  let calculatedColor = Color(color);

  const bgColor = theme === "light" ? "#FAFAFA" : "#1B1D1E";
  for (let i = 0; i < steps; i++) {
    if (calculatedColor.lighten(RATIO).contrast(Color(bgColor)) > constrastRatio) {
      calculatedColor = calculatedColor.lighten(RATIO);
    } else {
      break;
    }
  }
  return calculatedColor.rgb().array().join(" ");
};

export function generatePallette(color = "#056FDA", theme) {
  const bgColor = theme === "light" ? "#FAFAFA" : "#1B1D1E";
  const base = Color(color).contrast(Color(bgColor)) > constrastRatio ? Color(color) : Color("#056FDA");
  let secondaryColors = [
    "255 255 255",
    "249 250 251",
    "243 244 246",
    "229 231 235",
    "209 213 219",
    "156 163 175",
    "107 114 128",
    "75 85 99",
    "55 65 81",
    "31 41 55",
    "17 24 39",
  ];

  if (base.luminosity() > 0.5) secondaryColors = secondaryColors.reverse();
  return `
    --brand-50: ${base.lighten(RATIO).lighten(RATIO).lighten(RATIO).lighten(RATIO).lighten(RATIO).rgb().array().join(" ")};
    --brand-100: ${base.lighten(RATIO).lighten(RATIO).lighten(RATIO).lighten(RATIO).rgb().array().join(" ")};
    --brand-200: ${getLighterColor(base, 3, theme)};
    --brand-300: ${getLighterColor(base, 2, theme)};
    --brand-400: ${getLighterColor(base, 1, theme)};
    --brand-500: ${base.rgb().array().join(" ")};
    --brand-600: ${base.darken(RATIO).rgb().array().join(" ")};
    --brand-700: ${base.darken(RATIO).darken(RATIO).rgb().array().join(" ")};
    --brand-800: ${base.darken(RATIO).darken(RATIO).darken(RATIO).rgb().array().join(" ")};
    --brand-900: ${base.darken(RATIO).darken(RATIO).darken(RATIO).darken(RATIO).rgb().array().join(" ")};
    --brand-secondary-0: ${secondaryColors[0]};
    --brand-secondary-50: ${secondaryColors[1]};
    --brand-secondary-100: ${secondaryColors[2]};
    --brand-secondary-200: ${secondaryColors[3]};
    --brand-secondary-300: ${secondaryColors[4]};
    --brand-secondary-400: ${secondaryColors[5]};
    --brand-secondary-500: ${secondaryColors[6]};
    --brand-secondary-600: ${secondaryColors[7]};
    --brand-secondary-700: ${secondaryColors[8]};
    --brand-secondary-800: ${secondaryColors[9]};
    --brand-secondary-900: ${secondaryColors[10]};
  `;
}

export default function WidgetStylesheet({color, theme}) {
  const pallette = generatePallette(color, theme);
  const bgColor = theme === "light" ? "#FAFAFA" : "#1B1D1E";

  color ||= "#056FDA";
  let baseColor;
  try {
    baseColor = Color(bgColor).contrast(Color(color)) > constrastRatio ? Color(color) : Color("#056FDA");
  } catch {
    baseColor = Color("#056FDA");
  }
  const darkColor = baseColor.darken(0.1).string();
  const lightColor = baseColor.lighten(0.1).string();
  const fontColor = baseColor.isDark() ? "#fff" : "#1b1d1e";

  return (
    <Helmet>
      <style>
        {`
        ${
          pallette
            ? `.billetto-widget {
            ${pallette}
        }`
            : null
        }
        .billetto-widget *::selection {
          color: ${fontColor};
          background-color: ${baseColor.hex()};
        }
        .billetto-widget a {
          color: ${baseColor.hex()};
        }
        .billetto-widget .billetto-loader .ant-result-icon > .anticon {
          color: ${baseColor.hex()};
        }
        .billetto-widget a:hover,
        .billetto-widget a:focus {
          color: ${darkColor};
        }
        .billetto-widget a:active {
          color: ${lightColor};
        }
        .billetto-widget .button {
          background: ${baseColor.hex()};
          border-color: ${baseColor.hex()};
          color: ${fontColor};
        }
        .billetto-widget .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
        .billetto-widget .ant-input-number:hover,
        .billetto-widget .ant-input:focus,
        .billetto-widget .ant-input:hover {
          border-color: ${darkColor};
        }
        .billetto-widget .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .billetto-widget .ant-checkbox:hover .ant-checkbox-inner,
        .billetto-widget .ant-checkbox-input:focus .ant-checkbox-inner {
          border-color: ${baseColor.hex()};
        }
        .billetto-widget .ant-checkbox-checked .ant-checkbox-inner {
          background-color: ${baseColor.hex()};
          border-color: ${baseColor.hex()};
        }
        .billetto-widget .DayPicker-Month {
          border-collapse: separate;
          border-spacing: 4px;
        }
        .billetto-widget .DayPicker-Day {
          border: none;
        }
        .billetto-widget .DayPicker-Day--outside,
        .billetto-widget .DayPicker-Day--disabled {
          background-color: transparent;
        }
        .billetto-widget .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          color: ${fontColor};
          background-color: ${baseColor.hex()};
          background-image: none;
        }
        .billetto-widget .DayPicker-Day--available {
          color: ${baseColor.hex()};
          background-color: transparent;
          border-radius: 50%;
          border-color: ${baseColor.hex()};
          border-style: solid;
          border-width: 1px;
        }
        .billetto-widget .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          background-color: ${baseColor.hex()};
          color: ${fontColor};
        }
        .billetto-widget .expiration-timer--badge {
          border: 1px solid;
          border-radius: 4px;
          border-color: ${baseColor.hex()};
          padding: 4px;
          text-align: center;
        }
        .billetto-widget .expiration-timer--badge,
        .billetto-widget .expiration-timer--badge .ant-statistic-content-value,
        .billetto-widget .expiration-timer--badge .ant-statistic-content {
          color: ${baseColor.hex()};
        }
        .billetto-widget .order-summary {
          margin: -24px;
        }
        .billetto-widget .order-summary .order-summary-body {
          padding: 8px;
        }
        .billetto-widget .order-summary .order-summary-header {
          text-align: center;
          background-color: ${baseColor.hex()};
          border-color: ${baseColor.hex()};
          border-radius: 4px 4px 0 0;
          color: ${fontColor};
          padding: 8px;
        }
        .billetto-widget .order-summary .status-icon {
          font-size: 4em;
          display: inline-block;
          padding: 0.25em;
          border-radius: 50%;
          color: ${baseColor.hex()};
          background-color: ${fontColor};
          margin: 0.5em 0;
        }
        .billetto-widget .order-summary .title {
          font-weight: 400;
          font-size: 1em;
        }
        .billetto-widget .order-summary .ant-descriptions-item-label {
          font-weight: 700;
        }
      `}
      </style>
    </Helmet>
  );
}
