import {customNotify} from "~/bugsnag";
import {errorResponse} from "~/utils/index";

export default async function handleResponse(response, successMapper) {
  try {
    if (response.ok) return successMapper(await response.json());
    return await errorResponse(response);
  } catch (error) {
    if (error.name !== "AbortError") {
      customNotify(error);
      return {error};
    }
  }
}
