function parseCookies(cookie) {
  return cookie.split(/;\s+/).reduce((acc, c) => {
    const [name, value] = c.split("=");
    acc[name] = value;
    return acc;
  }, {});
}
class CookieJar {
  constructor(cookieString = document.cookie) {
    this.cookies = parseCookies(cookieString);
    this.listeners = new Set();
  }

  get(name) {
    return this.cookies[name];
  }

  set(name, value, {path = "/", expires}) {
    let cookie = `${name}=${value}`;
    if (expires) cookie += `; expires=${expires.toUTCString()}`;
    if (path) cookie += `; path=${path}`;
    document.cookie = cookie;
    this.refresh();
    this.listeners.forEach((listener) => listener());
  }

  listen(listener) {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }

  refresh() {
    this.cookies = parseCookies(document.cookie);
  }
}

export default new CookieJar();
