import {t} from "~/i18n";

export default function durationDetails(duration) {
  const durationRegExp = /^P(\d+)([DWMY])$/;
  if (!duration || !durationRegExp.test(duration)) return {};
  const result = duration.match(durationRegExp);
  const unit =
    {
      Y: "year",
      M: "month",
    }[result[2]] || "day";
  const number = unit === "W" ? 7 * parseInt(result[1]) : parseInt(result[1]);
  return {
    number,
    interval: t(`subscriptions.plans.interval.${unit}`, {count: number}),
  };
}
