import {createElement, forwardRef} from "react";

import Loading from "~/icons/Loading";
import classNames from "~/utils/classNames";

export default forwardRef(function Button(
  {
    children,
    icon,
    as = "button",
    size = "default",
    type = "default",
    rounded = false,
    loading = false,
    grouped = false,
    disabled,
    htmlType = "button",
    className,
    raw = false,
    block = false,
    ...props
  },
  ref,
) {
  const iconStyle =
    {
      xs: classNames("shrink-0 h-3 w-3", children ? "mr-1" : null),
    }[size] || classNames("shrink-0 h-4 w-4", children ? "mr-2" : null);
  const sizeStyles =
    {
      xs: classNames(
        rounded ? null : grouped ? "first:rounded-l last:rounded-r rounded-none" : "rounded",
        rounded ? "p-1.5" : "px-2.5 py-1.5",
        "text-xs",
      ),
      sm: classNames(
        rounded ? null : grouped ? "first:rounded-l-md last:rounded-r-md rounded-none" : "rounded-md",
        rounded ? "p-2" : "px-3 py-2",
        "leading-4 text-sm",
      ),
      lg: classNames(
        rounded ? null : grouped ? "first:rounded-l-md last:rounded-r-md rounded-none" : "rounded-md",
        rounded ? "p-2" : "px-4 py-2",
        "text-base",
      ),
      xl: classNames(
        rounded ? null : grouped ? "first:rounded-l-md last:rounded-r-md rounded-none" : "rounded-md",
        rounded ? "p-3" : "px-6 py-4",
        "text-base",
      ),
    }[size] ||
    classNames(
      rounded ? null : grouped ? "first:rounded-l-md last:rounded-r-md rounded-none" : "rounded-md",
      rounded ? "p-2" : "px-4 py-2",
      "text-sm",
    );
  const colorStyles =
    type === "custom"
      ? classNames(disabled ? "opacity-70" : null)
      : type === "primary"
        ? classNames(
            disabled
              ? "bg-brand-200 border-brand-200 hover:border-brand-300 hover:bg-brand-300 text-brand-secondary-50"
              : "bg-brand-500 border-brand-500 hover:border-brand-600 hover:bg-brand-600 text-brand-secondary-0",
          )
        : type === "secondary"
          ? classNames(
              disabled
                ? "bg-brand-200 border-brand-200 hover:border-brand-300 text-brand-secondary-50 hover:bg-brand-300 text-brand-secondary-50"
                : "bg-brand-400 hover:bg-brand-300 text-brand-secondary-0 border-brand-400 hover:border-brand-300",
            )
          : type === "light"
            ? classNames(
                "bg-brand-50 dark:bg-brand-800 text-brand-500 border-brand-500 hover:bg-brand-100 dark:hover:bg-brand-700 disabled:bg-brand-100 disabled:dark:bg-brand-600",
              )
            : type === "gray"
              ? classNames(
                  disabled
                    ? "bg-gray-700 border-gray-700 hover:border-gray-600 hover:bg-gray-600 text-white-50"
                    : "bg-gray-400 hover:bg-gray-500 text-white border-gray-400 hover:border-gray-500",
                )
              : type === "danger"
                ? classNames(
                    disabled
                      ? "text-gray-500 dark:text-gray-100 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-800"
                      : "bg-red-500 hover:bg-red-600 text-white hover:text-gray-50 border-red-700 hover:border-red-600",
                  )
                : type === "outline"
                  ? classNames(
                      "border-solid border-2",
                      disabled
                        ? "text-gray-500 border-gray-500 hover:border-gray-600 hover:bg-gray-600/10"
                        : "text-brand-500 border-brand-500 hover:border-brand-600 hover:bg-brand-600/10",
                    )
                  : classNames(
                      "dark:border-gray-700",
                      disabled
                        ? "text-gray-500 dark:text-gray-100 bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-800"
                        : "text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-900 border-solid border-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800",
                    );
  const buttonProps = {
    type: as === "button" ? htmlType || "button" : undefined,
    ...props,
    ref,
    disabled: disabled || loading,
    className: raw
      ? classNames(className, disabled || loading ? "cursor-not-allowed" : null, block ? "w-full justify-center" : null)
      : classNames(
          "inline-flex items-center border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-400",
          colorStyles,
          sizeStyles,
          className,
          disabled || loading ? "cursor-not-allowed" : null,
          block ? "w-full justify-center" : null,
          grouped ? "focus:z-20 first:ml-0 -ml-px" : null,
          rounded
            ? grouped
              ? "first:rounded-none first:rounded-r-full last:rounded-none last:rounded-r-full rounded-full"
              : "rounded-full"
            : null,
        ),
  };
  return createElement(
    as,
    buttonProps,
    <>
      {loading ? (
        <Loading className={classNames(iconStyle, "animate-spin inline text-current")} />
      ) : typeof icon === "function" || typeof icon?.render === "function" ? (
        createElement(icon, {
          className: classNames(iconStyle, "text-current inline"),
        })
      ) : null}
      <span className="leading-none">{children}</span>
    </>,
  );
});
