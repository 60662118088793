export default function shortDate(
  dateString,
  locale,
  timeZone,
  withTime = true
) {
  if (!dateString) return "";
  const localeString = locale === "en" ? "en-GB" : locale;
  try {
    return new Date(dateString).toLocaleDateString(localeString, {
      month: "short",
      year: "numeric",
      day: "numeric",
      hour: withTime ? "numeric" : undefined,
      minute: withTime ? "numeric" : undefined,
      timeZone,
    });
  } catch (_e) {
    return new Date(dateString).toLocaleDateString(localeString, {
      month: "short",
      year: "numeric",
      day: "numeric",
      hour: withTime ? "numeric" : undefined,
      minute: withTime ? "numeric" : undefined,
    });
  }
}
