import {differenceInDays, formatRelative} from "date-fns";
import {format as dateFormat, toZonedTime} from "date-fns-tz";

import {dateLocale} from "~/i18n";
import {getTimeZone} from "~/utils";

export default function formatDate(date, {format = "PPP pp", timeZone, relative = false, locale = dateLocale()} = {}) {
  const usedTimeZone = timeZone ?? getTimeZone();
  if (relative && Math.abs(differenceInDays(toZonedTime(date, usedTimeZone), new Date())) < 7)
    return formatRelative(toZonedTime(date, usedTimeZone), new Date(), {locale});

  return dateFormat(toZonedTime(date, usedTimeZone), format, {timeZone: usedTimeZone, locale});
}
