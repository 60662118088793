import {customNotify} from "~/bugsnag";

export default async function errorResponse(response) {
  try {
    if (/json/.test(response.headers.get("Content-Type"))) {
      const {errors, error} = await response.json();
      return {
        success: false,
        status: response.status,
        errors,
        error: Array.isArray(errors)
          ? {
              message: errors.map((e) => e.title)?.join(", "),
            }
          : error,
      };
    }
    return {
      success: false,
      status: response.status,
      errors: [{status: response.status.toString(), title: ""}],
      error: {},
    };
  } catch (error) {
    if (error.name !== "SyntaxError") customNotify(error);
    return {
      success: false,
      errors: [{status: response.status.toString(), title: error.message}],
      status: response.status,
      error,
    };
  }
}
