import {differenceInDays} from "date-fns";

import {customNotify} from "~/bugsnag";
import {t} from "~/i18n";
import compact from "~/utils/compact";

export const trackBillettoMessageNotification = () => {
  try {
    pushData({event: "notification-billetto-message"});
  } catch (e) {
    customNotify(e);
  }
};

export function getClientId() {
  return window.ga?.getAll?.()?.[0]?.get?.("clientId");
}

export const trackLoginSucceeded = (variant, loginType, userId, userIsOrganizer, identityEmail) => {
  const userType = userIsOrganizer ? "organizer" : "ticketbuyer";
  try {
    pushData({
      event: "login",
      loginSource: variant,
      loginType,
      loginStatus: "succeeded",
      userId,
      userType,
      errorMessage: "",
      identityEmail,
    });
  } catch (e) {
    customNotify(e);
  }
};

export const trackLogin = (userId, method) => {
  gtag("set", {user_id: userId});
  gtag("set", "user_properties", {
    account_number: userId,
  });
  gtag("event", "login", {
    user_id: userId,
    method: method,
  });
};
export const trackSignUp = (userId, method) => {
  gtag("set", {user_id: userId});
  gtag("set", "user_properties", {
    account_number: userId,
  });
  trackEvent("sign_up", {method, user_id: userId});
};

export const trackPreSignUp = () => {
  trackEvent("pre_sign_up", {});
};
export const trackCustomEvent = (category, action, label, value) => {
  try {
    pushData({
      event: "custom event",
      customEventCategory: category,
      customEventAction: action,
      customEventLabel: label,
      customEventValue: value,
    });
  } catch (e) {
    customNotify(e);
  }
};

export const trackLoginFailed = (variant, loginType, errorMessage) => {
  try {
    gtag("event", "login_error", {method: loginType});
    pushData({
      event: "login",
      loginSource: variant,
      loginType,
      loginStatus: "failed",
      userId: "N/A",
      userType: "",
      errorMessage,
    });
  } catch (e) {
    customNotify(e);
  }
};

export const trackSignupSucceeded = (variant, signupType, userId, userType, identityEmail) => {
  try {
    window.dataLayer[0].userId = userId;
    window.dataLayer[0].userType = userType;
    gtag("set", {user_id: userId});
    pushData({
      event: "signup",
      signupSource: variant,
      signupType,
      signupStatus: "succeeded",
      userId,
      userType,
      identityEmail,
      errorMessage: "",
    });
  } catch (e) {
    customNotify(e);
  }
};

export const trackB2BSignup = (userId) => {
  try {
    gtag("set", {user_id: userId});
    pushData({
      event: "b2b_signup",
      userId,
    });
  } catch (e) {
    customNotify(e);
  }
};

export function gtag() {
  window?.dataLayer?.push(arguments);
}

export const trackSignupFailed = (variant, signupType, errorMessage) => {
  try {
    pushData({
      event: "signup",
      signupSource: variant,
      signupType,
      signupStatus: "failed",
      userId: "N/A",
      userType: "",
      errorMessage,
    });
  } catch (e) {
    customNotify(e);
  }
};

export const trackLoginInitiated = (variant, signupType = "facebook") => {
  try {
    pushData({
      event: "login",
      signupSource: variant,
      signupType,
      signupStatus: "initiated",
      userId: "N/A",
      userType: "",
      errorMessage: "",
    });
  } catch (e) {
    customNotify(e);
  }
};

export const trackGetTicketsClicked = (eventName) => {
  try {
    pushData({
      event: "event",
      eventCategory: "tickets",
      eventAction: "find tickets",
      eventLabel: eventName,
    });
  } catch (e) {
    customNotify(e);
  }
};

export const trackGetTicketsDatePicked = (chosenDate) => {
  try {
    pushData({
      event: "event",
      eventCategory: "tickets",
      eventAction: "date selection",
      eventLabel: chosenDate,
    });
  } catch (e) {
    customNotify(e);
  }
};

const defaultNameFor = (pt) => {
  return {
    PayTicketType: t("events.form.ticket_types.pay_text"),
    AttendanceTicketType: t("events.form.ticket_types.attend_text"),
    FreeTicketType: t("events.form.ticket_types.free_text"),
    DonationTicketType: t("events.form.ticket_types.donation_text"),
    FoodAndBeverageTicketType: t("events.form.ticket_types.food_and_beverage"),
    MerchandiseTicketType: t("events.form.ticket_types.merchandise"),
    NoPdfPayTicketType: t("events.form.ticket_types.no_pdf_pay_text"),
  }[pt.type];
};

const rawTextFromHtmlText = (text) => {
  const node = document.createElement("DIV");
  node.innerHTML = text;
  return node.innerText ?? "";
};

export const trackEventPublished = (params, event) => {
  if (params.publish === "now") {
    const eventTicketTypes = event?.ticketTypes?.reduce((acc, tt) => {
      acc[tt.type] || (acc[tt.type] = 0);
      acc[tt.type] += tt.quantity;
      return acc;
    }, {});

    pushData({
      event: "event saved",
      eventAction: "published",
      eventTicketTypes,
    });
  }
};

export const trackEventCreated = ({
  eventId,
  description,
  unsplashImage,
  image,
  bannedOrganizer,
  type,
  category,
  subcategory,
  startsAt,
  endsAt,
  action,
  label,
  visibility,
  city,
  country,
  scheduledPublish,
  productTypes,
}) => {
  try {
    const eventTicketTypes = productTypes.reduce((acc, pt) => {
      acc[pt.type] || (acc[pt.type] = 0);
      acc[pt.type] += pt.quantity;
      return acc;
    }, {});
    const startsAtString = typeof startsAt === "string" ? startsAt : startsAt && startsAt.format();
    const endsAtString = typeof endsAt === "string" ? endsAt : endsAt && endsAt.format();
    const daysFromStart = startsAt && differenceInDays(new Date(startsAt), new Date());
    const ticketRenamed = !!productTypes.find((pt) => pt.name !== defaultNameFor(pt));
    const descriptionLength = rawTextFromHtmlText(description).length;
    const imageType = unsplashImage ? "stock" : image ? "custom" : "default";
    pushData({
      event: "event saved",
      eventId,
      eventCategory: "event",
      eventAction: action,
      eventStartDateTime: startsAtString,
      eventEndDateTime: endsAtString,
      eventVisibility: visibility,
      eventCity: city,
      eventLabel: label,
      eventCountry: country,
      eventCreationType: scheduledPublish ? "scheduled" : "active",
      eventTicketTypes,
      eventType: type,
      billettoEventCategory: category,
      billettoEventSubcategory: subcategory,
      daysFromStart,
      ticketRenamed,
      descriptionLength,
      imageType,
      bannedOrganizer,
    });
  } catch (e) {
    customNotify(e);
  }
};

export const trackError = ({errorType, errorMessage}) => {
  pushData({
    event: "error",
    errorType,
    errorMessage,
  });
};

export const pushData = (data) => {
  window.dataLayer && window.dataLayer.push(data);
};

export function trackEvent(event, payload = {}) {
  try {
    const ga4_payload = compact({
      ...payload,
      widget: !!payload.extra?.widgetId,
      extra: null,
      ...flattenExtra(payload.extra, "algolia"),
    });
    gtag("event", event, ga4_payload);
  } catch (e) {
    customNotify(e);
  }
  if (payload.extra?.widgetId) {
    dispatchWidgetMessage(event, payload);
  }
}

function dispatchWidgetMessage(event, payload) {
  window.parent.postMessage({type: "billetto-widget", event: event, payload: payload}, "*");
}

function flattenExtra(extra, key) {
  if (extra && extra[key]) {
    return Object.entries(extra[key]).reduce((a, [k, v]) => ((a[`${key}_${k}`] = v), a), {});
  } else {
    return {};
  }
}
export const trackWidgetViewed = (eventId, widgetId) => {
  dispatchWidgetMessage("viewed", {event_id: eventId, extra: {widgetId: widgetId}});
};

export function trackEventCreatorInitiated() {
  trackEvent("event_creator_initiated", {});
}

export function trackEditorStepSubmitted(step, payload) {
  trackEvent(`event_creator_${step}_completed`, payload);
}

export function eventItemPayload(event, tags, affiliation = "website", extra = {}) {
  return compact({
    ...extra,
    item_id: event.id,
    item_name: event.name,
    item_brand: event.host,
    item_category: event.category,
    item_category2: event.subcategory,
    event_construct: [event.venueCategory, event.kind].join(" / "),
    event_status: event.state,
    event_type: event.type,
    affiliation,
    promoted_event: event.promoted,
    promoted_source: event.promoted && tags.source,
    promoted_campaign: event.promoted && tags.campaign,
    promoted_medium: event.promoted && tags.medium,
    promoted_content: event.promoted && tags.content,
  });
}

export function ecommercePayload(order, affiliation = "website") {
  const {event, tags} = order;
  const eventPayload = eventItemPayload(event, tags, affiliation);
  const items = order.orderLines.map((line) => ({
    ...eventPayload,
    item_type: line.addons ? "add-on" : "ticket",
    item_variant: line.name,
    price: parseFloat(line.price),
    discount: parseFloat(line.discount || 0),
    item_fee: parseFloat(line.fee),
    quantity: 1,
  }));
  order.orderRows.forEach((row) =>
    items.push({
      item_id: row.sku,
      item_name: row.name,
      item_type: "service",
      affiliation: order.widgetId ? "widget" : "website",
      price: parseFloat(row.price),
      quantity: 1,
    }),
  );
  return {
    currency: order.currency,
    value: items.reduce((acc, item) => acc + item.price, 0.0),
    items,
  };
}
