export default function reportMetric(name, tags = {}) {
  try {
    const params = new URLSearchParams();
    Object.keys(tags).forEach((key) => params.append(`t[${key}]`, tags[key]));
    const pixel = document.createElement("IMG");
    pixel.alt = "";
    pixel.width = "1";
    pixel.height = "1";
    pixel.style.display = "inline";
    pixel.src = `/pixels/${encodeURI(name)}.png?${params}`;
    document.body.appendChild(pixel);
    return true;
  } catch (error) {
    return false;
  }
}
