export function union(a, b) {
  const result = new Set();
  a.forEach((item) => result.add(item));
  b.forEach((item) => result.add(item));
  return result;
}

export function difference(a, b) {
  const result = new Set();
  a.forEach((item) => !b.has(item) && result.add(item));
  return result;
}

export function intersect(a, b) {
  const result = new Set();
  a.forEach((item) => b.has(item) && result.add(item));
  return result;
}
