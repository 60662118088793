import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link, useHistory} from "react-router-dom";

import {eventSalesStatusUrl, ticketBuyerEventUrl} from "~/apiRoutes";
import Btn from "~/components/Button";
import Messenger, {TYPES} from "~/embeddable/Messenger";
import fetchJsonApi from "~/fetchers/fetchJsonApi";
import useBackendData from "~/hooks/useBackendData";
import Lingo from "~/modules/lingo";
import {eventUrl} from "~/routes";
import {widgetEventUrl} from "~/ticket_buyer/routes";

export default function Button({
  inline = false,
  event,
  headless,
  hideBanners,
  color,
  organization,
  whitelabel,
  theme,
  noDirectSales,
  url,
  widgetId,
  thirdParty = false,
  ...props
}) {
  const {ready, t, i18n} = useTranslation();
  const [locale, setLocale] = useState(i18n.language);
  const history = useHistory();
  const iframeRef = useRef();
  const ButtonElement = headless ? "button" : Btn;
  const iframeUrl = useMemo(() => {
    const query = {
      theme,
      organization,
      color,
      locale: locale,
      wid: widgetId,
    };
    if (organization !== window.location.host) {
      query.utm_source = "Billetto";
      query.utm_medium = "Widget";
      query.utm_campaign = "Organiser site";
      query.utm_content = window.location?.toString();
    }
    if (whitelabel) query.whitelabel = true;
    if (hideBanners) query["hide-banners"] = true;
    return (
      event &&
      widgetEventUrl({
        id: event,
        domain: organization,
        query,
      })
    );
  }, [event, theme, organization, color, hideBanners, whitelabel, locale]);
  const {data: status, links} = useBackendData(
    event && organization ? eventSalesStatusUrl({eventId: event, domain: organization}) : null,
    {
      fetcher: fetchJsonApi,
    },
  );
  const lingo = useMemo(() => {
    return Lingo(status?.lingo ?? "ticket");
  }, [status]);
  const [show, setShow] = useState(false);
  const hideOverlay = useCallback(
    (_, event) => {
      if (new RegExp(`https?://${organization}$`).test(event.origin)) {
        setShow(false);
        const iframeNode = iframeRef.current;
        if (iframeNode && document.body.contains(iframeNode)) document.body.removeChild(iframeNode);
      }
    },
    [organization],
  );
  const handleRedirect = useCallback(
    (url, event) => {
      if (new RegExp(`https?://${organization}$`).test(event.origin)) window.location.href = url;
    },
    [organization],
  );

  const buttonProps = useMemo(() => {
    switch (status?.state) {
      case "sold_out":
        return {...props, children: t("events.buy_tickets.sold_out"), disabled: true};
      case "coming_soon":
        return {...props, children: t("events.buy_tickets.no_tickets_for_sale_yet"), disabled: true};
      case "disabled":
        return {...props, children: lingo.t("buy_tickets_button_text_closed"), disabled: true};
      case "waitlist":
        return {
          ...props,
          children: t("events.join_waitlist.button_text"),
          as: headless ? undefined : "a",
          target: headless ? undefined : "_blank",
          href: headless ? undefined : links.waitlist,
          onClick: headless
            ? () => {
                window.open(links.waitlist, "_blank");
              }
            : undefined,
        };
      case "reserved":
        return {...props, children: t("ticket.buy_tickets_button_text_reserved"), disabled: true};
      default:
        return {
          ...props,
          children: lingo.t("buy_tickets_button_text"),
          onClick: () => {
            if (!thirdParty) return history.push(iframeUrl);
            setShow(true);
            const iframeNode = document.createElement("iframe");
            iframeNode.setAttribute("allow", "payment");
            iframeNode.setAttribute("allowFullScreen", "");
            iframeNode.setAttribute("src", iframeUrl);
            iframeNode.style.position = "fixed";
            iframeNode.style.top = "0px";
            iframeNode.style.left = "0px";
            iframeNode.style.margin = "0";
            iframeNode.style.padding = "0";
            iframeNode.style.border = "none";
            iframeNode.style.width = "100vw";
            iframeNode.style.height = "100dvh";
            iframeNode.style.zIndex = "2147483647";
            iframeNode.style.backgroundColor = "rgba(0,0,0,0.25)";
            if (iframeRef.current && document.body.contains(iframeRef.current)) {
              document.body.replaceChild(iframeNode, iframeRef.current);
            } else document.body.appendChild(iframeNode);
            iframeRef.current = iframeNode;
          },
        };
    }
  }, [props, t, status?.state, links?.waitlist, lingo, iframeUrl, thirdParty]);

  useEffect(() => {
    const messenger = new Messenger(window);
    messenger.on(TYPES.HIDE, hideOverlay);
    messenger.on(TYPES.ESCAPE, handleRedirect);
    return () => {
      messenger.destroy();
    };
  }, [hideOverlay]);
  useEffect(() => {
    i18n.on("languageChanged", setLocale);
    return () => i18n.off("languageChanged", setLocale);
  }, []);

  const {data} = useBackendData(ticketBuyerEventUrl({eventId: event, domain: organization}), {fetcher: fetchJsonApi});

  if (!event || !organization || !ready) return null;

  if (noDirectSales && data) {
    const url = eventUrl({slug: data?.slug, domain: organization, id: event, query: {locale}});
    return <ButtonElement {...buttonProps} onClick={undefined} href={url} target="_blank" as="a" type="primary" />;
  }
  if (inline)
    return status?.state === "waitlist" ? (
      <ButtonElement {...buttonProps} as="a" type="primary" />
    ) : (
      <ButtonElement {...buttonProps} as={Link} to={widgetEventUrl({id: event, query: {locale}})} type="primary" />
    );

  return (
    <>
      {status ? (
        <ButtonElement
          {...buttonProps}
          loading={headless ? undefined : !ready || !status}
          type={headless ? undefined : "primary"}
          disabled={show || buttonProps.disabled}
        />
      ) : (
        <ButtonElement {...props} loading={headless ? undefined : true} type={headless ? undefined : "primary"} />
      )}
    </>
  );
}
